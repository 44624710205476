import React, { useEffect, useState } from 'react';
import classnames from 'classnames';

const LoadingBar: React.FC = () => {
    const [colorIndex, setColorIndex] = useState(0);
    const colorsArr = [
        'transparent', // transparent
        '#FF0000', // Red
        '#FF7F00', // Orange
        '#FFFF00', // Yellow
        '#00FF00', // Green
        '#0000FF', // Blue
        '#4B0082', // Indigo
        '#9400D3', // Violet
    ];

    useEffect(() => {
        const interval = setInterval(() => {
            setColorIndex((prevIndex) => (prevIndex + 1) % colorsArr.length);
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    return (
        <div className={classnames('h-1', 'w-full', 'rounded-full', 'overflow-hidden')}>
            <div
                className={classnames('h-1', 'w-full', 'absolute', 'transition-colors', 'duration-1000')}
                style={{ background: colorsArr[colorIndex] }}
            ></div>
        </div>
    );
};

export default LoadingBar;
