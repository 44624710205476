import {
    createUserWithEmailAndPassword,
    onAuthStateChanged,
    signInWithEmailAndPassword,
    sendPasswordResetEmail,
    signOut,
} from 'firebase/auth';
import { auth } from './firebase';
import { getFirebaseAuthI18nErrorMsgAsync } from '../libs/i18n-lib/i18n-firebase-auth-errors';

export const getCurrentUserAuthObj = () => {
    return auth.currentUser;
};

type CreateUserProps = {
    lang: string;
    email: string;
    password: string;
};

export const createNewUserAsync = async ({
    lang,
    email,
    password,
}: CreateUserProps) => {
    try {
        const userCredential = await createUserWithEmailAndPassword(
            auth,
            email,
            password
        );
        const user = userCredential.user;
        return user;
    } catch (err: unknown) {
        const errorCode = (err as CustomError).code || '';
        (err as CustomError).message = await getFirebaseAuthI18nErrorMsgAsync({
            errorCode,
            lang,
        });

        throw err;
    }
};

type SignInUserProps = {
    lang: string;
    email: string;
    password: string;
};

export const signInAsync = async ({
    lang,
    email,
    password,
}: SignInUserProps) => {
    try {
        const userCredential = await signInWithEmailAndPassword(
            auth,
            email,
            password
        );
        const user = userCredential.user;
        return user;
    } catch (err: unknown) {
        const errorCode = (err as CustomError).code || '';
        (err as CustomError).message = await getFirebaseAuthI18nErrorMsgAsync({
            errorCode,
            lang,
        });

        throw err;
    }
};


type ForgotPasswordUserProps = {
    lang: string;
    email: string;
};

export const forgotPasswordAsync = async ({
    lang,
    email,
}: ForgotPasswordUserProps) => {
    try {
        await sendPasswordResetEmail(
            auth,
            email
        );
    } catch (err: unknown) {
        const errorCode = (err as CustomError).code || '';
        (err as CustomError).message = await getFirebaseAuthI18nErrorMsgAsync({
            errorCode,
            lang,
        });

        throw err;
    }
};

type StateChangeCbProps = {
    uid: string;
};

type StateChangeCb<T> = (value: T) => void;

export const onUserAuthStateChanged = (
    cb: StateChangeCb<StateChangeCbProps>
) => {
    return onAuthStateChanged(auth, (user) => {
        if (user) {
            // User is signed in, see docs for a list of available properties
            // https://firebase.google.com/docs/reference/js/auth.user
            const uid = user.uid;
            cb({ uid });
            // ...
        } else {
            // User is signed out
            // ...
            cb({ uid: '' });
        }
    });
};

type SignOutUserProps = {
    lang: string;
};

export const signOutAsync = async ({ lang }: SignOutUserProps) => {
    try {
        return await signOut(auth);
    } catch (err: unknown) {
        const errorCode = (err as CustomError).code || '';
        (err as CustomError).message = await getFirebaseAuthI18nErrorMsgAsync({
            errorCode,
            lang,
        });

        throw err;
    }
};
