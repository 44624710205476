import { supportedLanguages } from '../../../configs/i18n.config';
import i18nStringsJson from '../../../i18n/firebase-auth-errors/strings.json';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const i18nStringsObj: any = i18nStringsJson;

type GetFirebaseAuthI18nErrorMsgAsyncProps = {
    errorCode: string;
    lang: string;
};

export const getFirebaseAuthI18nErrorMsgAsync = async ({
    errorCode,
    lang,
}: GetFirebaseAuthI18nErrorMsgAsyncProps) => {
    if (!supportedLanguages.includes(lang)) {
        throw new Error(`Language ${lang} is NOT supported!`);
        return;
    }

    try {
        const dynamicI18nStringsObj = await import(
            `../../../i18n/firebase-auth-errors/strings-${lang}.json`
        );
        return dynamicI18nStringsObj[errorCode] || i18nStringsObj[errorCode];
    } catch (err) {
        return i18nStringsObj[errorCode];
    }
};
