import React, { useEffect } from 'react';
import { fullRedirect } from '../../utils/window';

type Props = {
    href: string;
};

export default function FullRedirect({ href }: Props) {
    useEffect(() => {
        fullRedirect({ href })
    }, [])
    return <span>Redirecting...</span>
}
