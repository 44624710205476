import React from 'react';
import { supportedGoogleApiFontFamliyNamesArr } from '../../configs/fonts.config';
import { Helmet } from 'react-helmet';

export default function LoadFontFamliyNames() {
    return (
        <Helmet>
            {supportedGoogleApiFontFamliyNamesArr.map(({ value }) => {
                return (
                    <link
                        key={value}
                        rel="preload"
                        as="style"
                        href={`https://fonts.googleapis.com/css?family=${value.replaceAll(
                            ' ',
                            '+'
                        )}&display=swap&effect=shadow-multiple|3d-float`}
                    />
                );
            })}
            {supportedGoogleApiFontFamliyNamesArr.map(({ value }) => {
                return (
                    <link
                        key={value}
                        href={`https://fonts.googleapis.com/css?family=${value.replaceAll(
                            ' ',
                            '+'
                        )}&display=swap&effect=shadow-multiple|3d-float`}
                        rel="stylesheet"
                        media="screen"
                    />
                );
            })}
        </Helmet>
    );
}
