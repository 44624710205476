const myQrCodesPagePath = '/my-qr-codes';

export const linkPathConfig = {
    loginPagePath: '/login',
    forgotPasswordPagePath: '/forgot-password',
    changePasswordPagePath: '/change-password',
    signUpPagePath: '/sign-up',
    homePagePath: '/',
    settingsPagePath: '/my-settings',
    myProfilePagePath: '/my-profile',
    myQrCodesPageObj: {
        path: myQrCodesPagePath,
        myQrCodeEditPagePath: ':id/edit',
    },
    qrCodeEditorPagePath: '/qr-code-editor',
    aboutPagePath: '/about',
};

export const getLoginPageUrl = (): string => {
    return linkPathConfig.loginPagePath;
};

export const getForgotPasswordPageUrl = (): string => {
    return linkPathConfig.forgotPasswordPagePath;
};

export const getChangePasswordPageUrl = (): string => {
    return linkPathConfig.changePasswordPagePath;
};

export const getSignUpPageUrl = (): string => {
    return linkPathConfig.signUpPagePath;
};

export const getHomePageUrl = (): string => {
    return linkPathConfig.homePagePath;
};

export const getSettingsPageUrl = (): string => {
    return linkPathConfig.settingsPagePath;
};

export const getMyProfilePageUrl = (): string => {
    return linkPathConfig.myProfilePagePath;
};

export const getMyQrCodesPageUrl = (): string => {
    return linkPathConfig.myQrCodesPageObj.path;
};

export const getMyQrCodeEditPageUrl = (id: string): string => {
    return [
        linkPathConfig.myQrCodesPageObj.path,
        linkPathConfig.myQrCodesPageObj.myQrCodeEditPagePath.replace(':id', id),
    ].join('/');
};

export const getQrCodeEditorPageUrl = (): string => {
    return linkPathConfig.qrCodeEditorPagePath;
};

export const getAboutUrl = (): string => {
    return linkPathConfig.aboutPagePath;
};
