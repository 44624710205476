import React, { lazy, Suspense } from 'react';
import {
    RouteObject,
    RouterProvider,
    createBrowserRouter,
    useRouteError,
} from 'react-router-dom';
import { linkPathConfig } from '../libs/link-gen';
import ProtectedRoute from '../components/ProtectedRoute';
import FullRedirect from '../components/FullRedirect';
import { showOnlyInDev } from '../configs/dev.config';
import LoadingBar from '../components/LoadingBar';

const HomePage = lazy(() => import('../pages/HomePage'));
// const AboutPage = lazy(() => import( '../pages/AboutPage'));
const MyQrCodeEditPage = lazy(() => import('../pages/MyQrCodeEditPage'));
const MyQrCodesPage = lazy(() => import('../pages/MyQrCodesPage'));
const QrCodeEditorPage = lazy(() => import('../pages/QrCodeEditorPage'));
const LoginPage = lazy(() => import('../pages/LoginPage'));
const SignUpPage = lazy(() => import('../pages/SignUpPage'));
const ForgotPasswordPage = lazy(() => import('../pages/ForgotPasswordPage'));
const ChangePasswordPage = lazy(() => import('../pages/ChangePasswordPage'));
const MyProfilePage = lazy(() => import('../pages/MyProfilePage'));
const SettingsPage = lazy(() => import('../pages/SettingsPage'));

function ErrorElement() {
    const error = useRouteError();
    console.error(error);
    // Uncaught ReferenceError: path is not defined
    return <h1>Something went wrong.</h1>;
}

const routesArr = [
    showOnlyInDev && {
        path: linkPathConfig.loginPagePath,
        element: <LoginPage />,
        errorElement: <ErrorElement />,
    },
    showOnlyInDev && {
        path: linkPathConfig.forgotPasswordPagePath,
        element: <ForgotPasswordPage />,
        errorElement: <ErrorElement />,
    },
    showOnlyInDev && {
        path: linkPathConfig.changePasswordPagePath,
        element: (
            <ProtectedRoute>
                <ChangePasswordPage />
            </ProtectedRoute>
        ),
        errorElement: <ErrorElement />,
    },
    showOnlyInDev && {
        path: linkPathConfig.signUpPagePath,
        element: <SignUpPage />,
        errorElement: <ErrorElement />,
    },
    {
        path: linkPathConfig.homePagePath,
        element: <HomePage />,
        errorElement: <ErrorElement />,
    },
    showOnlyInDev && {
        path: linkPathConfig.settingsPagePath,
        element: (
            <ProtectedRoute>
                <SettingsPage />
            </ProtectedRoute>
        ),
        errorElement: <ErrorElement />,
    },
    showOnlyInDev && {
        path: linkPathConfig.myProfilePagePath,
        element: (
            <ProtectedRoute>
                <MyProfilePage />
            </ProtectedRoute>
        ),
        errorElement: <ErrorElement />,
    },
    showOnlyInDev && {
        path: linkPathConfig.myQrCodesPageObj.path,
        element: (
            <ProtectedRoute>
                <MyQrCodesPage />
            </ProtectedRoute>
        ),
        errorElement: <ErrorElement />,
        children: [
            {
                path: linkPathConfig.myQrCodesPageObj.myQrCodeEditPagePath,
                element: (
                    <ProtectedRoute>
                        <MyQrCodeEditPage />
                    </ProtectedRoute>
                ),
                errorElement: <ErrorElement />,
            },
        ],
    },
    {
        path: linkPathConfig.qrCodeEditorPagePath,
        element: <QrCodeEditorPage />,
        errorElement: <ErrorElement />,
    },
    /*
    {
        path: linkPathConfig.aboutPagePath,
        element: <AboutPage />,
        errorElement: <ErrorElement />,
    },
    */
    {
        path: '*',
        element: <FullRedirect href="/404.html" />,
        errorElement: <ErrorElement />,
    },
].filter((item) => !!item) as RouteObject[];

export default function AppRoutes() {
    const router = createBrowserRouter(routesArr);

    return <Suspense fallback={<LoadingBar />}>
        <RouterProvider router={router} />
    </Suspense>;
}
