import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AppRoutes from '../AppRoutes';
import LoadFontFamliyNames from '../components/LoadFontFamliyNames';
import { LanguageProvider } from '../providers/LanguageProvider';
import { IsLoggedInProvider } from '../providers/IsLoggedInProvider';

export default function App() {
    return (
        <>
            <LanguageProvider>
                <IsLoggedInProvider>
                    <LoadFontFamliyNames />
                    <AppRoutes />
                    <ToastContainer />
                </IsLoggedInProvider>
            </LanguageProvider>
        </>
    );
}
