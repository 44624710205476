// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
// import { getRemoteConfig } from "firebase/remote-config";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: 'AIzaSyDysExwgpQbfquwFszKNoxZk4zZpCI4pi0',
    authDomain: 'qr-code-gen-web.firebaseapp.com',
    projectId: 'qr-code-gen-web',
    storageBucket: 'qr-code-gen-web.appspot.com',
    messagingSenderId: '320641813060',
    appId: '1:320641813060:web:5adf8bcf7014c8d38a0a11',
    measurementId: 'G-P324DW6SCG',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const analytics = getAnalytics(app);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);

// Initialize Cloud Firestore and get a reference to the service
export const db = getFirestore(app);

/*
// Initialize Remote Config and get a reference to the service
export const remoteConfig = getRemoteConfig(app);
remoteConfig.settings.minimumFetchIntervalMillis = 3600000;
*/
