type FullRedirectProps = {
    href: string
}

export const fullRedirect = ({ href }: FullRedirectProps) => {
    window.location.href = href;
};

export const disableScroll = () => {
    // Get the current page scroll position
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const scrollLeft =
        window.pageXOffset || document.documentElement.scrollLeft;

    // if any scroll is attempted, set this to the previous value
    window.onscroll = () => {
        window.scrollTo(scrollLeft, scrollTop);
    };
};

export const enableScroll = () => {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    window.onscroll = () => { };
};
