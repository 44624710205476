import React, { createContext, useEffect, useState } from 'react';
import {
    getCurrentUserAuthObj,
    onUserAuthStateChanged,
} from '../../firebase/auth';

type Props = {
    children?: React.ReactNode;
};

export interface IsLoggedInContextProps {
    isLoading: boolean;
    isLoggedIn: boolean;
    uid: string;
}

const getUserUid = () => getCurrentUserAuthObj()?.uid || '';

const initUid = getUserUid();

export const IsLoggedInContext = createContext<IsLoggedInContextProps>({
    isLoading: true,
    isLoggedIn: !!initUid,
    uid: initUid,
});

export function IsLoggedInProvider({ children }: Props) {
    const initUid = getUserUid();
    const [isLoadingState, setIsLoadingState] = useState<boolean>(true);
    const [isLoggedInState, setIsLoggedInState] = useState<boolean>(!!initUid);
    const [uidState, setUidState] = useState<string>(initUid);

    const setStateValues = () => {
        const uidValue = getUserUid();

        setUidState(() => uidValue);
        setIsLoggedInState(() => !!uidValue);
    };

    useEffect(() => {
        setStateValues();

        const unsubscribe = onUserAuthStateChanged(() => {
            setStateValues();
            setIsLoadingState(() => false);
        });

        // Clean up the observer when the component unmounts
        return () => unsubscribe();
    }, []);

    return (
        <IsLoggedInContext.Provider
            value={{
                isLoading: isLoadingState,
                isLoggedIn: isLoggedInState,
                uid: uidState,
            }}
        >
            {children}
        </IsLoggedInContext.Provider>
    );
}
