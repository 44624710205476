export const textExampleArabic = 'النص الخاص بك';
export const textExampleEnglish = 'Your Text';

export const fontArabic = 'Arabic';
export const fontEnglish = 'English';

export const defaultFontName = 'Marhey';

export const supportedGoogleApiFontFamliyNamesArr = [
    { title: 'Marhey', value: 'Marhey', langs: [fontArabic] },
    { title: 'Rakkas', value: 'Rakkas', langs: [fontArabic] },
    { title: 'Lemonada', value: 'Lemonada', langs: [fontArabic] },
    { title: 'El Messiri', value: 'El Messiri', langs: [fontArabic] },
    { title: 'Cairo', value: 'Cairo', langs: [fontArabic] },
    {
        title: 'IBM Plex Sans Arabic',
        value: 'IBM Plex Sans Arabic',
        langs: [fontArabic],
    },
    { title: 'Tajawal', value: 'Tajawal', langs: [fontArabic] },
    { title: 'Ubuntu Mono', value: 'Ubuntu Mono', langs: [fontArabic] },
];

export const supportedFontFamliyNamesArr = [
    ...supportedGoogleApiFontFamliyNamesArr,
    {
        title: 'Helvetica (sans-serif)',
        value: 'Helvetica',
        langs: [fontEnglish],
    },
    { title: 'Arial (sans-serif)', value: 'Arial', langs: [fontEnglish] },
    {
        title: 'Arial Black (sans-serif)',
        value: 'Arial Black',
        langs: [fontEnglish],
    },
    { title: 'Verdana (sans-serif)', value: 'Verdana', langs: [fontEnglish] },
    {
        title: 'Tahoma (sans-serif)',
        value: 'Tahoma',
        langs: [fontEnglish, fontArabic],
    },
    {
        title: 'Trebuchet MS (sans-serif)',
        value: 'Trebuchet MS',
        langs: [fontEnglish],
    },
    { title: 'Impact (sans-serif)', value: 'Impact', langs: [fontEnglish] },
    {
        title: 'Gill Sans (sans-serif)',
        value: 'Gill Sans',
        langs: [fontEnglish],
    },
    {
        title: 'Times New Roman (serif)',
        value: 'Times New Roman',
        langs: [fontEnglish],
    },
    { title: 'Georgia (serif)', value: 'Georgia', langs: [fontEnglish] },
    { title: 'Palatino (serif)', value: 'Palatino', langs: [fontEnglish] },
    {
        title: 'Baskerville (serif)',
        value: 'Baskerville',
        langs: [fontEnglish],
    },
    {
        title: 'Andalé Mono (monospace)',
        value: 'Andalé Mono',
        langs: [fontEnglish],
    },
    { title: 'Courier (monospace)', value: 'Courier', langs: [fontEnglish] },
    { title: 'Lucida (monospace)', value: 'Lucida', langs: [fontEnglish] },
    { title: 'Monaco (monospace)', value: 'Monaco', langs: [fontEnglish] },
    {
        title: 'Bradley Hand (cursive)',
        value: 'Bradley Hand',
        langs: [fontEnglish],
    },
    {
        title: 'Brush Script MT (cursive)',
        value: 'Brush Script MT',
        langs: [fontEnglish],
    },
    { title: 'Luminari (fantasy)', value: 'Luminari', langs: [fontEnglish] },
    {
        title: 'Comic Sans MS (cursive)',
        value: 'Comic Sans MS',
        langs: [fontEnglish],
    },
];
