import React, { createContext, useEffect, useState } from 'react';
import { defaultLanguage, supportedLanguages } from '../../configs/i18n.config';

type Props = {
    children?: React.ReactNode;
};

export interface LanguageContextProps {
    lang: string;
}

export const LanguageContext = createContext<LanguageContextProps>({
    lang: defaultLanguage, // Default language
});

export function LanguageProvider({ children }: Props) {
    const [langState, setLangState] = useState<string>('en');

    useEffect(() => {
        const userLanguage =
            navigator.language; /* || navigator.userLanguage; */

        // Check if user's language is supported, otherwise fallback to default language
        if (supportedLanguages.includes(userLanguage)) {
            setLangState(userLanguage);
        } else {
            setLangState(defaultLanguage);
        }
    }, []);

    return (
        <LanguageContext.Provider value={{ lang: langState }}>
            {children}
        </LanguageContext.Provider>
    );
}
