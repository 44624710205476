import React from 'react';
import { Navigate } from 'react-router-dom';
import { getHomePageUrl } from '../../libs/link-gen';
import { useIsLoggedIn } from '../../hooks/UseIsLoggedIn';

type ProtectedRouteProps = {
    children: React.ReactNode;
};

export default function ProtectedRoute({ children }: ProtectedRouteProps) {
    const { isLoading, isLoggedIn } = useIsLoggedIn();

    if (isLoading) {
        return <p>Loading...</p>;
    }

    if (!isLoggedIn) {
        // user is not authenticated
        return <Navigate to={getHomePageUrl()} />;
    }

    return <>{children}</>;
}
